import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import { baseUrl } from 'utils/axios'

interface TinyMCEEditorProps {
  value: string
  onChange: (value: string) => void
}

export default function TinyMCEEditor({ value, onChange }: TinyMCEEditorProps) {
  const apiKey = process.env.REACT_APP_TINY_API_KEY
  const editorRef = useRef<Editor>(null)

  const handleEditorChange = (e: any) => {
    onChange(e.target.getContent())
  }

  const imageUploadHandler = async (blobInfo: any, success: any) => {
    const loadedFileName = await dispatch(uploadFileThunk(blobInfo?.blob()))
    success(`${baseUrl}/files/tmp/${loadedFileName}`)
  }

  return (
    <>
      <Editor
        ref={editorRef}
        apiKey={apiKey}
        initialValue={value}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste',
            'paste image',
          ],
          paste_data_images: true,
          toolbar:
            'undo redo | formatselect | bold italic | \
                      alignleft aligncenter alignright | \
                      bullist numlist outdent indent | help',
          language: 'ru',
          file_picker_types: 'image',
          images_upload_handler: imageUploadHandler,
          content_style: 'body { font-family: "Montserrat",sans-serif; }',
        }}
        onChange={handleEditorChange}
      />
    </>
  )
}
