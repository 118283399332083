import { isString } from 'lodash'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { useSnackbar } from 'notistack'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, Theme, Typography, Paper } from '@mui/material'
import { SxProps } from '@mui/system'
// utils
import { fData } from '../../utils/formatNumber'
//
import { UploadIllustration } from '../../assets'

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
}))

// ----------------------------------------------------------------------

interface CustomFile extends File {
  path?: string
  preview?: string
}

interface UploadSingleFileProps extends DropzoneOptions {
  error?: boolean
  file: CustomFile | string | null
  sx?: SxProps<Theme>
  onDrop: (acceptedFiles: any) => Promise<void>
}

export default function UploadSingleFile({
  error = false,
  file,
  sx,
  onDrop,
  ...other
}: UploadSingleFileProps) {
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = (file: File[]) => {
    if (file[0]?.type.includes('image')) {
      const img = new Image()
      img.src = URL.createObjectURL(file[0])
      img.onload = async function () {
        if (img.width > 1920 || img.height > 1080) {
          enqueueSnackbar('Максимальное разрешение файла - 1920×1080', {
            variant: 'error',
          })
        } else {
          onDrop(file)
        }
      }
      img.onerror = function () {
        onDrop(file)
      }
    } else {
      onDrop(file)
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    onDrop: handleDropFile,
    ...other,
  })

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: theme => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size }: CustomFile = file
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map(e => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        )
      })}
    </Paper>
  )

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && { padding: '12% 0' }),
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            Перетащите или выберите файл
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Перенесите файл в данную область или &nbsp;
            <Typography
              variant="body2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              выберите
            </Typography>
            &nbsp;из списка на вашем устройстве
          </Typography>
        </Box>

        {file && (
          <Box
            component="img"
            alt="file preview"
            src={isString(file) ? file : file.preview}
            sx={{
              top: 8,
              borderRadius: 1,
              objectFit: 'cover',
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
            }}
          />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}
    </Box>
  )
}
